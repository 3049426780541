import React, { useEffect, useState } from 'react'
import GoogleButton from 'react-google-button'
import firebase from 'firebase'
import './index.css'
import { loginUser, logoutUser} from './api'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem('isDarkTheme') === null || localStorage.getItem('isDarkTheme') === 'true')


  useEffect(() => {
    localStorage.setItem('isDarkTheme', isDarkTheme)
    var element = document.body;
    if(!isDarkTheme) {
      element.classList.remove("dark-mode")
    } else {
      element.classList.add("dark-mode")
    }
  }, [isDarkTheme])

  useEffect(() => {
    firebase.initializeApp({
      apiKey: 'AIzaSyBJmUI_PIL23j0w7-WVWXL5A-SDK0i1eE8',
      authDomain: 'probable-lore-302720.firebaseapp.com',
      databaseURL: 'https://probable-lore-302720-default-rtdb.firebaseio.com',
      projectId: 'probable-lore-302720',
      storageBucket: 'probable-lore-302720.appspot.com',
      messagingSenderId: '105908127862',
      appId: '1:105908127862:web:5230b9d0139d9338f47757',
      measurementId: 'G-ENDK2RVECY',
    })

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken()
        loginUser(token)
          .then((result)=>{
            if(result?.status === 200){
              const urlParams = new URLSearchParams(window.location.search)
              const redirect = urlParams.get('redirect')
              if (redirect) {
                window.location = redirect
              }
              setIsLoggedIn(true)
              return
            }
            console.error('error calling login api')
            firebase.auth().signOut()
            return
          })
          .catch((error) => {
            console.error(error)
            firebase.auth().signOut()
            return
          }) 
          return
      }
      // else
      logoutUser()
        .then(()=>{
          setIsLoggedIn(false)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }, [])

  return (
    <div className='flex flex-col items-center justify-center p-10'>
      {!isLoggedIn && (
        <>
          <p className='font-semibold text-2xl mb-4'>Sign Into Economy</p>
          
          <div className='items-center justify-center p-2 inline'>
          <GoogleButton
            label='Sign in with Google'
            type={isDarkTheme ? 'dark' : 'light'}
            onClick={async () => {
              const provider = new firebase.auth.GoogleAuthProvider()
              firebase.auth().languageCode = 'en'
              try {
                firebase.auth().signInWithPopup(provider)
              } catch(error) {
                console.error(error)
								firebase.auth().signOut()
              }
            }}
          />
          </div>

          <div className='items-center justify-center p-2 inline'>
          <GoogleButton
            label='Sign up with Google'
            type={isDarkTheme ? 'dark' : 'light'}
            onClick={async () => {
              const provider = new firebase.auth.GoogleAuthProvider()
              firebase.auth().languageCode = 'en'
              try {
                firebase.auth().signInWithPopup(provider)
              } catch(error) {
                console.error(error)
								firebase.auth().signOut()
              }
            }}
          />
          </div>
          <div className='mt-2'>
            <Toggle
              defaultChecked={isDarkTheme}
              className='custom align-middle' 
              onChange={()=>setIsDarkTheme(!isDarkTheme)} />
            <span className='ml-1 align-middle'>Dark Mode</span>
          </div>
        </>
      )}
      {isLoggedIn && (
        <>
        <p className='font-semibold text-2xl'>You're logged in!</p>
        <div className='items-center justify-center p-2 inline'>
          <GoogleButton
            label='Sign out'
            type={isDarkTheme ? 'dark' : 'light'}
            onClick={async () => {
              try {
                firebase.auth().signOut()
              } catch(error) {
                  console.error(error)
              }
            }}
          />
        </div>
      </>
      )}
    </div>
  )
}

export default App
