const API_BASE = `https://api.joineconomy.com/v1`

// Contact the economy backend letting them know a user has signed in
// This allows economy backend to create a new user if needed, set a cookie, etc
export const loginUser = async (token) => {
	return fetch(
		`${API_BASE}/login`,
		{
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			method: 'POST',
		},
	)
		.then((response) => response)
		.catch(() => null)
}

export const logoutUser = async () => {
	return fetch(
		`${API_BASE}/logout`,
		{
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
		}
	)
		.then((response) => response)
		.catch(() => null)
}